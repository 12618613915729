import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  num1: '',
  num2: '',
  num3: '',
  num: '',
};

export const numberInputSlice = createSlice({
  name: 'numberInput',
  initialState,
  reducers: {
    setNum: (state, action) => {
      state.num = action.payload;
    },
    setNum1: (state, action) => {
      state.num1 = action.payload;
    },
    setNum2: (state, action) => {
      state.num2 = action.payload;
    },
    setNum3: (state, action) => {
      state.num3 = action.payload;
    },
  },
});

export const { setNum1, setNum2, setNum3, setNum } = numberInputSlice.actions;

export default numberInputSlice.reducer;

export const handleSetNum = (data) => {
  dispatch(setNum(data));
};

export const handleSetNum1 = (data) => {
  dispatch(setNum1(data));
};

export const handleSetNum2 = (data) => {
  dispatch(setNum2(data));
};

export const handleSetNum3 = (data) => {
  dispatch(setNum3(data));
};

export const handleClearNumberInput = () => {
  dispatch(setNum1(''));
  dispatch(setNum2(''));
  dispatch(setNum3(''));
  dispatch(setNum(''));
};
