import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const nums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const nums1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const nums2 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const initialState = {
  range: { title: nums[0] },
  range1: { title: nums1[0] },
  range2: { title: nums2[0] },
  data: nums,
  data1: nums1,
  data2: nums2,
};

export const numberPickerSlice = createSlice({
  name: 'numberPicker',
  initialState,
  reducers: {
    setRange: (state, action) => {
      const { name, value } = action.payload;
      state.range = { ...state.range, [name]: value };
    },
    setRange1: (state, action) => {
      const { name, value } = action.payload;
      state.range1 = { ...state.range1, [name]: value };
    },
    setRange2: (state, action) => {
      const { name, value } = action.payload;
      state.range2 = { ...state.range2, [name]: value };
    },
  },
});

export const { setRange, setRange1, setRange2 } = numberPickerSlice.actions;

export default numberPickerSlice.reducer;

export const handleSetRange = (data) => {
  dispatch(setRange(data));
};

export const handleSetRange1 = (data) => {
  dispatch(setRange1(data));
};

export const handleSetRange2 = (data) => {
  dispatch(setRange2(data));
};

export const handleClearNumberPicker = () => {
  dispatch(setRange({ name: 'title', value: '0' }));
  dispatch(setRange1({ name: 'title', value: '0' }));
  dispatch(setRange2({ name: 'title', value: '0' }));
};
