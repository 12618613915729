// @mui
import { enUS } from '@mui/material/locale';

// API
// ----------------------------------------------------------------------
// export const APP_URL = window.location.origin;

// export const API_URL = 'http://127.0.0.1:8000/api/';
// export const MEDIA_URL = 'http://127.0.0.1:8000/';



export const API_URL = 'https://royal-backend.360-digital.net/api/';
export const MEDIA_URL = 'https://royal-backend.360-digital.net/media/';

// MULTI LANGUAGES

export const allLangs = [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'MM',
    value: 'mm',
  },
];

export const defaultLang = allLangs[0]; // English

// NOTIFICATION ROUTES
export const notificationRoutes = [
  {
    channel: 'wallet-channel',
    route: '/wallet/request-list/',
  },
  {
    channel: 'checkout-channel',
    route: '/wallet/checkout-list/',
  },
];
