const mm = {
  // Tabs
  Home: `အိမ်`,
  Player: `ကစားသမား`,
  Wallet: `ပိုက်ဆံအိတ်`,
  Transaction: `ငွေပေးငွေယူ`,
  Account: `အကောင့်`,

  // Button
  Create: 'ပြုလုပ်မည်',
  Cancel: 'မပြုလုပ်တော့ပါ',
  Accept: 'လက်ခံမည်',
  Reject: 'ငြင်းပယ်မည်',
  Next: 'နောက်တစ်ဆင့်',
  Request: 'တောင်းဆိုမည်',
  Confirm: 'အတည်ပြုမည်',
  Save: 'မှတ်ထားမည်',
  CancelSaving: 'မလုပ်မှတ်ထားတော့ပါ',
  Send: 'ပို့မည်',
  Edit: 'ပြုပြင်မည်',
  // Common
  deposit: 'ငွေဖြည့်ခြင်း',
  withdraw: 'ငွေထုတ်ခြင်း',
  Accepted: 'လက်ခံပြီး',
  Rejected: 'ငြင်းပယ်ပြီး',
  Status: 'အခြေအနေ',
  Bet: 'ထိုးခဲ့သောမှတ်တမ်း',
  Deposit: 'ငွေဖြည့်ထားသော မှတ်တမ်း',
  Withdraw: 'ငွေထုတ်ထားသော မှတ်တမ်း',
  ThaiLottery: 'Thai Lottery',
  SingaporeSweep: 'Singapore Sweep',
  SelectDate: 'ရက်စွဲ ရွေးမည်',
  Name: 'အမည်',
  WinningAmount: 'အနိုင်ရရှိသော ပမာဏ',
  Income: 'ဝင်ငွေ',
  Outgoing: 'ထွက်ငွေ',
  // Home Page
  FlowWallet: 'လက်ကျန်ငွေ',
  CommissionWallet: 'ကော်မရှင်',
  TotalPlayer: 'ထိုးသား စုစုပေါင်း',
  ActivePlayer: 'လက်ရှိ ထိုးသား',
  Requests: 'တောင်းဆိုမှုများ',
  // Plaer Page
  PlayerManagement: 'ထိုးသား စီမံမှု',
  CreateNewPlayer: 'ထိုးသားအကောင့်သစ် ပြုလုပ်မည်',
  PlayersName: 'ထိုးသား အမည်',
  EnterPlayersName: 'ထိုးသား အမည် ထည့်ပါ',
  PhoneNumber: 'ဖုန်းနံပါတ်',
  EnterPhoneNumber: 'ဖုန်းနံပါတ် ထည့်ပါ',
  Password: 'စကားဝှက်',
  EnterPassword: 'စကားဝှက် ထည့်ပါ',
  ConfirmPassword: 'အတည်ပြု စကားဝှက်',
  EnterConfirmPassword: 'အတည်ပြု စကားဝှက် ထည့်ပါ',
  // Player Detail
  TotalBetAmount: 'စုစုပေါင်း ထိုးခဲ့သော ငွေပမာဏ',
  TotalWinAmount: 'စုစုပေါင်း နိုင်ခဲ့သော ငွေပမာဏ',
  TicketNumbers: 'ထီနံပါတ်များ',
  Details: 'အသေးစိတ် အချက်အလက်များ',
  ResetPassword: 'စကားဝှက် အသစ်ပြန်လုပ်မည်',
  BetDetail: 'ထိုးခဲ့သော အချက်အလက် အသေးစိတ်',
  // Wallet Page
  WalletManagement: 'ပိုက်ဆံအိတ် စီမံခြင်း',
  Checkout: 'ငွေစာရင်းရှင်းတမ်း',
  Commission: 'ကော်မရှင်',
  // Request Page
  Pending: 'လုပ်ဆောင်နေဆဲ',
  History: 'မှတ်တမ်း',
  // History Detail
  HistoryDetail: 'မှတ်တမ်း အသေးစိတ်',
  Type: 'အမျိုးအစား',
  Date: 'ရက်စွဲ',
  Time: 'အချိန်',
  RequestTime: 'တောင်းဆိုသည့် အချိန်',
  PlayerName: 'ထိုးသား အမည်',
  PlayerID: 'ထိုးသား အမှတ်',
  PaymentType: 'ငွေပေးချေမှု အမျိုးအစား',
  AccountName: 'အကောင့်အမည်',
  AccountNumber: 'အကောင့်နံပါတ်',
  AgentAccountName: 'အေးဂျင့် အကောင့်အမည်',
  AgentAccountNumber: 'အေးဂျင့် အကောင့်နံပါတ်',
  TransactionID: 'လုပ်ဆောင်မှု အမှတ်စဥ်',
  EnterTransactionID: 'လုပ်ဆောင်မှု အမှတ်စဥ် ထည့်မည်',
  Amount: 'ငွေပမာဏ',
  EnterRejectNote: 'ငြင်းပယ်ရသည့်အကြောင်းအရင်း ထည့်ပါ',
  EnterAddNote: 'မှတ်ချက် ထည့်ပါ',
  AddNote: 'မှတ််ချက်ရေးမည်',
  // Checkout Page
  RequestCheckout: 'ငွေစာရင်းရှင်းမည်',
  CheckoutType: 'ငွေစာရင်း အမျိုးအစား',
  FlowAmount: 'ငွေစာရင်းပမာဏ',
  EnterFlowAmount: 'ငွေစာရင်းပမာဏ ထည့်ပါ',
  SelectAdminPaymentMethod: 'admin ၏ ငွေပေးချေမှုစနစ်အား ရွေးချယ်ပါ',
  AdminAccountName: 'admin အကောင့်အမည်',
  AdminAccountNumber: 'admin အကောင့်နံပါတ်',
  PaymentStatus: 'ငွေပေးချေခြင်း အခြေအနေ',
  PaidOn: 'ငွေပေးချေသည့် အချိန်',
  CheckoutRequest: 'ငွေစာရင်း ရှင်းမည်',
  PayLater: 'နောက်မှ ပေးမည်',
  PayNow: 'အခု ပေးမည်',
  PaymentDate: 'ငွေချေမည့် ရက်',
  PaymentNote: 'ငွေချေခြင်း အတွက် မှတ်ချက်',
  Note: 'မှတ်ချက်',
  // Commission Page
  TotalSale: 'စုစုပေါင်းရောင်းရငွေ',
  // Transaction Page
  // Profile Page
  Profile: 'ပရိုဖိုင်',
  ProfileDetails: 'ပရိုဖိုင် အသေးစိတ်',
  ChangePassword: 'စကားဝှက်ပြောင်းလဲမည်',
  ContactInformation: 'ဆက်သွယ်ရန် အချက်အလက်များ',
  PaymentMethod: 'ငွေပေးချေမှုစနစ်',
  PaymentMethods: 'ငွေပေးချေမှုစနစ်များ',
  Notification: 'အသိပေးချက်',
  Notifications: 'အသိပေးချက်များ',
  AgentCode: 'အေးဂျင့်ကုဒ်',
  Copy: 'ကူးမည်',
  Logout: 'ထွက်မည်',
  // Change Password Page
  CurrentPassword: 'လက်ရှိ စကားဝှက်',
  EnterCurrentPassword: 'လက်ရှိ စကားဝှက်အား ထည့်ပါ',
  NewPassword: 'စကားဝှက် အသစ်',
  EnterNewPassword: 'စကားဝှက်အသစ်အား ထည့်ပါ',
  ConfirmNewPassword: 'စကားဝှက်အသစ်အား အတည်ပြုပါ',
  EnterConfirmPassword: 'အတည်ပြုထားသောစကားဝှက်ကို ထည့်ပါ',
  // Contact information page
  MyContactInformation: 'ကျွန်ပ်၏ ဆက်သွယ်ရန် အချက်အလက်များ',
  AddNewContactInformation: 'ဆက်သွယ်ရန် အချက်အလက်အသစ်ထည့်မည်',
  SelectContactInformation: 'ဆက်သွယ်ရန် အချက်အလက် ရွေးချယ်ပါ',
  EditContactInformation: 'အချက်အလက်များကို ပြောင်းလဲခြင်း',
  Number: 'နံပါတ်',
  EnterNumber: 'နံပါတ် ထည့်ပါ',
  // Payment Method
  MyPaymentMethod: 'ကျွန်ုပ်၏ ငွေပေးချေမှုစနစ်များ',
  AddNewPaymentMethod: 'ငွေပေးချေမှုစနစ် အသစ်ထည့်မည်',
  EditPaymentMethod: 'ငွေပေးချေမှုစနစ်အား ပြောင်းလဲခြင်း',
  SelectPaymentMethod: 'ငွေပေးချေမှုစနစ်အား ရွေးချယ်ပါ',
  EnterAccountName: 'အကောင့် အမည် ထည့်ပါ',
  EnterAccountNumber: 'အကာင့် နံပါတ် ထည့်ပါ',
  RedirectToPayment: 'ငွေပေးချေမှုနည်းလမ်း မတွေ့ပါ။ ငွေပေးချေမှုအသစ်ထည့်ပါ',
  MinKyats: 'အနည်းဆုံး 100 ကျပ်',
  DefaultAmountForNums: 'နံပါတ်အားလုံးအတွက် ထိုးမည့်ငွေပမာဏကိုထည့်ရန်',
  ConfirmSelectedNums: 'ရွေးချယ်ထားသောနံပါတ်များ အတည်ပြုမည်',
  LoopByNums: '‌ခွေထိုးမည်',
  BetByLoops: '‌ခွေထိုးမည်',
  Min3Nums: 'အနည်းဆုံး နံပါတ်သုံးလုံးထည့်ပါ',
  SmallNums: 'နံပါတ်အသေး',
  BigNums: 'နံပါတ်အကြီး',
  Constellation: 'နက္ခတ်',
  Power: 'ပါဝါ',
  Brothers: 'ညီအကို',
  Twins: 'အပူး',

  Even: 'စုံ',
  Odd: 'မ',
  EvenEven: 'စုံစုံ',
  EvenOdd: 'စုံမ',
  OddOdd: 'မမ',
  OddEven: 'မစုံ',
  SingleLoop: 'တစ်လုံးတည်းပတ်',
  FirstNums: 'ထိပ်စီးများ',
  LastNums: 'နောက်ပိတ်များ',
  LuckyNum: 'ပေါက်ဂဏန်း',
  Others: 'အခြား',
  SingleBet: 'တစ်ခုချင်းရွေးထိုးမည်',
  QuickBet: 'အမြန်ထိုးမည်',
  ClosedTime: 'ထီပိတ်ချိန်',
  Odds: 'ဆ',
  SelectBetType: 'ထိုးမည့်အမျိုးအစား ရွေးချယ်ပါ',
  SelectTimePeriod: 'ထိုးမည့်အချိန် ရွေးချယ်ပါ',
  PlayersNumber: 'ထိုးသား ဖုန်းနံပါတ်',
  EnterPlayersNumber: 'ထိုးသား ဖုန်းနံပါတ်ထည့်ပါ',
  Betting: 'ထိုးမည်',
  SetNums: 'နံပါတ်များ ထည့်ပါ',
  SelectNums: 'နံပါတ်များကို ရွေးချယ်ပါ',
  Apply: 'ထည့်မည်',
  TotalAmount: 'စုစုပေါင်း ပမာဏ',
  Balance: 'ကျန်ငွေ',
  TimePeriodList: 'ထီပေါက်ချိန် များ',
  LuckyNumber: 'ပေါက်ဂဏန်း',
  SaleList: 'အရောင်းစာရင်း',
  WinnerList: 'ထီပေါက်သူ စာရင်း',
  TimePeriodSaleList: 'ထီပေါက်ချိန်၏ အရောင်းစာရင်း',
  SaleDetails: 'အရောင်းစာရင်း အသေးစိတ်',
  Period: 'ထီပေါက်ချိန်',
  BetAmount: 'ထိုးငွေ ပမာဏ',
  PlayerAccountName: 'ထိုးသား အကောင့်အမည်',
  PlayerAccountNumber: 'ထိုးသား အကောင့်နံပါတ်',
  BetNumbers: 'ထိုးခဲ့သော နံပါတ်များ',
  TotalWinAmount: 'စုစုပေါင်း အနိုင်ရရှိသော ပမာဏ',
  PayWinner: 'အနိုင်ရရှိသူများကို ငွေချေမည်',
  UnpaidedAmount: 'ကျန်ရှိသော ပမာဏ',
  PaidedAmount: 'ပေးချေပြီးသော ပမာဏ',
  PaidedStatus: 'ငွေပေးချေမှု အခြေအနေ',
  PayAmount: 'ပေးချေမည့် ပမာဏ',
  Paid: 'ပေးမည်',
  SaleBet: 'ရောင်းမည်',
  Report: 'စားရင်းချုပ်',
  RemainingPaidToWinner: 'ငွေထုတ်ပေးရန်ကျန်သေးသည်',
  Paid: 'ငွေပေးချေပြီး',
  PartiallyPaid: 'ငွေပေးချေရန် ကျန်ရှိနေပါသေးသည်',
  Unpaid: 'ငွေမချေရသေးပါ',
  PaidedAmount: 'ပေးချေပြီးသော ပမာဏ',
  RemainingAmount: 'ကျန်ရှိနေသေးသော ပမာဏ',
  PaidStatus: 'ငွေပေးချေမှု အခြေအနေ',
  RecievedPaymentAmount: 'လက်ခံရရှိသော ငွေပမာဏ',
};

export default mm;
