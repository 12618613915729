import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import authReducer from './slices/auth';
import playerReducer from './slices/player';
import walletReducer from './slices/wallet';
import transactionReducer from './slices/transaction';
import notiReducer from './slices/noti';
import numberPickerReducer from './slices/numberPicker';
import numberInputReducer from './slices/numberInput';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducer,
  player: playerReducer,
  wallet: walletReducer,
  transaction: transactionReducer,
  noti: notiReducer,
  numberPicker: numberPickerReducer,
  numberInput: numberInputReducer,
});

export { rootPersistConfig, rootReducer };
