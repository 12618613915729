import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import BeatLoader from 'react-spinners/BeatLoader';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';
import palette from '../../theme/palette';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      <ProgressBar />

      <RootStyle {...other}>
        <Box transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}>
          <BeatLoader color={palette.red} />
        </Box>
      </RootStyle>
    </>
  );
}
